<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: ['visible', 'loyalty-reward'],
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      alert: false,
    }
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    show: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close', false);
        }
      },
    },
  },
  methods: {
    ...mapActions("loyalty", [
      'redeemLoyaltyReward',
    ]),
    async redeem() {
      await this.redeemLoyaltyReward({
        id: this.loyaltyReward.id,
        data: {
          merchantId: this.profile.merchant_id,
          points: this.loyaltyReward.points,
        }
      });

      this.alert = true;
      setTimeout(() => {
        this.alert = false;
        this.show = false;
      }, 3000);
    },
  }
};
</script>
<template>
  <v-dialog v-model="show" :content-class="``" transition="dialog-bottom-transition" @click:outside="show = false;">
    <v-card>
      <v-img
        :src="`${cdnUrl}/${loyaltyReward.cover}`"
      ></v-img>
      <v-card-text class="bg-transparent p-0">
        <div class="bg-white w-100 h-100 rounded-t-xl">
          <v-alert
            v-if="alert"
            class="alert text-h6 font-weight-6 p-3 mt-6"
            type="success"
            transition="fade-transition"
            >Redeem successful!</v-alert
          >
          <v-row class="text-center m-0 p-0">
            <v-col cols="12">
              <h5 class="font-weight-bold">{{ loyaltyReward.code }}</h5>
            </v-col>
          </v-row>
          <v-row class="text-center m-0 p-0">
            <v-col class="pt-0" style="border-right:0.55px solid rgba(0, 0, 0, 0.2);" cols="6">
              <div class="text-body-2 font-weight-6">Points</div>
              <div class="mt-1" style="font-size:12px;">{{ loyaltyReward.points }} pts</div>
            </v-col>
            <v-col class="pt-0" cols="6">
              <div class="text-body-2 font-weight-6">Validity</div>
              <div class="mt-1" style="font-size:12px;">1 Aug 2023 to 31 Oct 2023</div>
            </v-col>
          </v-row>
          <v-divider class="mt-0"></v-divider>
          <v-row class="text-center m-0 p-0">
            <v-col class="text-left my-0 py-0" cols="12">
              <div class="text-body-2 font-weight-6">Term & Conditions</div>
              <div class="text-body-2 font-weight-5 mt-2">
                <ul>
                  <li>Only for Delivery & Pickup</li>
                  <li>Min. Purchase RM0</li>
                  <li>Cannot exchange for cash</li>
                </ul>
              </div>
            </v-col>
          </v-row>
          <div class="text-center w-100" style="position:absolute; bottom:-13vh;">
            <v-btn
              color="primary"
              class="text-body-2 font-weight-6 white--text w-50 py-6"
              rounded
              :disabled="alert"
              @click="redeem()"
              >Redeem</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.v-card /deep/ .v-card__text {
  position: relative;
  top: -15vh;
  height: calc(100% - 48px);
}

.v-card /deep/ .v-card__text > div {
  border-top: 3px solid var(--v-primary);
}

/* .v-card /deep/ .v-card__actions {
  position: relative;
  top: -48px;
} */
</style>
