<template>
  <v-card style="min-height: 100vh" class="bg-light" elevation="0">
    <v-app-bar outlined short color="#ffffff" elevation="0.5">
      <v-icon @click="back()">mdi-arrow-left</v-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title
        align-self="center"
        class="text-button font-weight-6 grey--text text--darken-2 p-0 m-0"
      >
        Rewards
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-sheet
      height="calc(100vh - 48px)"
      color="#f3f3f3"
      :class="`${ isLoggedIn ? '' : 'pb-56' } overflow-y-auto p-0`"
    >
      <v-card flat class="m-3" v-if="rewards.length === 0">
        <v-list color="#ffffff">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 12px !important"
                class="text-caption text-center font-weight-bold mt-2"
              >
                No reward found
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <div v-else>
        <v-card flat class="mx-3 mt-3">
          <v-list color="#ffffff">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="text-body-2 text-center font-weight-6"
                >
                  You have <span class="h4 font-weight-bold tertiary--text mx-1">{{ pointBalance }}</span> pts
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <div class="reward-container row m-0 p-2">
          <div
            class="col-6 p-1"
            v-for="reward in rewards"
            :key="`reward-col-${reward.id}`"
          >
            <v-card
              class="m-1"
              flat
              elevation="5"
              @click="viewLoyaltyReward(reward)"
            >
              <v-img class="cover-photo" :src="`${cdnUrl}/${reward.cover}`">
              </v-img>
              <v-card-title class="subtitle-2 break-word p-2"
                ><b>
                  {{ reward.code }}
                </b></v-card-title
              >
              <v-card-actions class="p-2 pt-0">
                <div
                  class="
                    subtitle-2
                    d-flex
                    align-items-center
                    justify-content-center
                    font-weight-600
                  "
                  style="letter-spacing: normal; height: 36px"
                >
                  <div class="d-flex flex-column">
                    <div
                      class="text--disabled caption font-weight-600"
                      v-if="reward.discount"
                    >
                      <strike>{{ reward.points }}</strike>
                    </div>

                    <div>
                      {{ reward.points }} pts
                    </div>
                  </div>
                </div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </v-sheet>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <view-modal
      :visible="showLoyaltyReward"
      :loyalty-reward="selectedLoyaltyReward"
      @close="showLoyaltyReward = false;"
      v-if="showLoyaltyReward"
    ></view-modal>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import ViewModal from '@/components/Loyalty/Reward/ViewModal';

export default {
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      keyword: '',
      isLoading: false,
      selectedLoyaltyReward: null,
      showLoyaltyReward: false,
    };
  },
  components: {
    ViewModal,
  },
  computed: {
    ...mapState('loyalty', {
      rewards: (state) => state.rewards,
      pointBalance: (state) => state.pointBalance,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('auth', {
      isLoggedIn: ['isLoggedIn'],
    }),
    ...mapGetters('cart', {
      serviceIcons: ['getServiceIcons'],
    }),
  },
  methods: {
    ...mapActions('loyalty', ['getLoyaltyRewards', 'getLoyaltyPointBalance']),
    ...mapMutations('cart', ['SET_SERVICE', 'SET_VOUCHER']),
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY');
    },
    viewLoyaltyReward(loyaltyReward) {
      this.selectedLoyaltyReward = loyaltyReward;
      this.showLoyaltyReward = true;
    },
    back() {
      if (this.$route.query.id || this.prevRoute?.name == 'login') {
        this.$router.push({ name: 'home' }).catch(() => {});
      } else {
        this.$router.go(-1);
      }
    },
    apply(voucher) {
      if (this.$route.query.id && !voucher) {
        voucher = this.claimedVoucher;
      }

      this.$gtag.event('apply-voucher', {
        event_category: 'engagement',
        event_label: `Apply Voucher (${voucher?.restaurantName})`,
        event_value: voucher,
      });

      if (voucher?.restaurantHostname === location.hostname) {
        this.SET_VOUCHER({
          id: voucher.id,
          code: voucher.code,
          type: voucher.type,
          amount: voucher.amount,
          min_purchase: voucher.minPurchase,
          item: voucher.item,
        });

        this.showServiceSheet(voucher?.delivery, voucher?.pickup, voucher?.dinein);
      } else {
        const iframe = document.getElementById('sso').contentWindow;
        iframe.postMessage(
          {
            action: 'apply-voucher',
            value: voucher,
          },
          '*'
        );

        window.location.href = `https://${voucher?.restaurantHostname}`;
      }
    },
    claim(voucher) {
      this.$router.push({ name: 'login', query: { redirectFullPath: `/vouchers?id=${voucher.id}` } }).catch(() => {});
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.prevRoute = from);
  },
  async mounted() {
    // Get Loyalty Point Balance
    this.getLoyaltyPointBalance({});

    // Get Loyalty Rewards
    this.getLoyaltyRewards({
      merchantId: this.profile.merchant_id,
    })
  },
};
</script>
<style scoped>
.v-list-item__action-text {
  color: #ff6347 !important;
}
.pb-56 {
  padding-bottom: 56px !important;
}

.reward-container /deep/ .v-card {
  border: 4px solid var(--v-secondary);
}

.limited-offer {
  font-size: 8px !important;
  font-weight: 600;
  height: 18px;
  border-radius: 8px;
  color: #039be5 !important;
  background-color: rgba(3, 155, 229, 0.1) !important;
}
.limited-quantity {
  font-size: 10px !important;
  font-weight: bold;
  height: 20px;
  border-radius: 8px;
  color: #ff6347 !important;
  background-color: rgba(255, 99, 71, 0.1) !important;
}
</style>